<template>
  <div class="container">
    <Instructions />
  </div>
</template>

<script>
import Instructions from '@/components/Instructions'

export default {
  components: {
    Instructions
  }
}
</script>
